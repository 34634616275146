import defaultMoment from 'moment';
import moment from 'moment-timezone';

export const getEnglishDateWithTime = (date) => {
  if (!!date) {
    return moment(date).format('MMM D, YYYY h:mm A');
  }
  return '';
};

export const getEnglishDate = (date) => {
  if (!!date) {
    return moment(date).format('MMM D, YYYY');
  }
  return '';
};

export const getEnglishTime = (date) => {
  if (!!date) {
    return moment(date).format('h:mm A');
  }
  return '';
};

export const conv2time = (datetime) =>
  moment(moment(datetime).format('HHmm'), 'HHmm');

export const firstDayOfMonth = (date) => {
  return defaultMoment(date).startOf('month').format('d');
};

export const lastDayOfMonth = (date) => {
  return defaultMoment(date).endOf('month').format('D');
};

export const layoutDaysOfMonth = (year, month) => {
  const date = defaultMoment([year, month - 1, 1]);
  const firstDay = firstDayOfMonth(date);
  const lastDay = lastDayOfMonth(date);

  return Array.from({ length: 42 })
    .map((_, idx) => {
      const day = idx - firstDay + 1;
      if (day < 1 || day > lastDay) {
        return null;
      } else {
        return day;
      }
    })
    .reduce((acc, day, idx) => {
      const row = Math.floor(idx / 7);
      return {
        ...acc,
        [row]: [...(acc?.[row] || []), day],
      };
    }, {});
};
